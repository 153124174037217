import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Logo";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import Constants from "../../Utils/Constants";

export default class RegisterVerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationStatus: null,
    };
  }

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
    if (this.props.match.params.verificationStatus) {
      this.setState({
        verificationStatus: this.props.match.params.verificationStatus.replace(
          /\+/g,
          " "
        ),
      });
    }
  }

  render() {
    return (
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-12 col-md-6  mx-auto ">
              <div className="auth-form-light text-center p-5 ">
                <div className="brand-logo">
                  <Logo />
                </div>
                {this.props.match.params.verificationStatus == "success" ? (
                  <>
                    <h1>
                      <i className="mdi mdi-email-outline text-success"></i>
                    </h1>
                    <h4 className="text-success">
                      Your account is now active!
                    </h4>
                    <h6 className="font-weight-light mb-3 text-darker">
                      Now that we have verified your email, <br />
                      you may now login to your profile.
                    </h6>
                    <a
                      href={Constants.subfolder + "/login"}
                      className="btn  btn-success btn-md font-weight-medium btn-fw"
                    >
                      Login
                    </a>
                  </>
                ) : (
                  <>
                    <h1>
                      <i className="mdi mdi-email-outline text-danger"></i>
                    </h1>
                    <h4 className="text-danger">An error occurred</h4>
                    <h6 className="font-weight-light mb-3 text-darker">
                      Your request failed,
                      <br />
                      {this.state.verificationStatus}
                    </h6>
                    <Link
                      to={"/"}
                      className="btn btn-dark text-white font-weight-medium btn-fw mr-2"
                    >
                      Home
                    </Link>
                    <Link
                      to={"/login"}
                      className="btn btn-success text-white font-weight-medium btn-fw"
                    >
                      Login
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
      </div>
    );
  }
}
