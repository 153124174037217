import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import Tabs from "../../Utils/Tabs";
import CenterBlock from "../../Components/CenterBlock";
import UserBio from "../../Components/UserBio";
import Functions from "../../Utils/Functions";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <MainLayout title="Profile">
        <div className="row">
          <div className="col-md-12">
            <CardBlock>
              <UserBio />
            </CardBlock>
          </div>
        </div>
      </MainLayout>
    );
  }
}
