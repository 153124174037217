import React, { Component } from "react";
import APICall from "../Utils/APICall";
import CenterBlock from "./CenterBlock";
import Spinner from "../Utils/Spinner";
import Modal from "react-modal";
import MyTable from "../Utils/MyTable";
import Functions from "../Utils/Functions";
import SpinnerButton from "../Utils/SpinnerButton";
import Toastr from "../Utils/Toastr";
import "../sass/user-bio.scss";
import PageError from "./PageError";
import Constants from "../Utils/Constants";
import { DataContext } from "../Contexts/DataContexts";

export default class UserBio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      errorMessage: null,
      newRecord: {},
      newRecordErrorMessage: null,
      submitLoading: false,
      loggedInUser: null,
      profileImageloading: false,
      hovered: false,
      updatePayload: {},
      queryString: Functions.randomString(6),
    };

    this.init = this.init.bind(this);
  }

  static contextType = DataContext;

  changeDisplayPhoto(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      this.postDP(reader.result);
    };
    reader.readAsDataURL(file);
  }

  postDP = (base64File) => {
    if (!base64File || base64File.trim() <= 10) return;
    this.setState({ errorMessage: null, profileImageloading: true });
    APICall("api/users/ChangeProfilePicture", "POST", {
      userId: this.state.data.id,
      base64Image: base64File,
    })
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({
            profileImageloading: false,
            queryString: Functions.randomString(10),
          });
        } else {
          Toastr("error", response.message);
          this.setState({
            profileImageloading: false,
          });
        }
      })
      .catch((error) => {
        Toastr("success", "Please check your network");
        this.setState({
          profileImageloading: false,
        });
      });
  };

  componentDidMount() {
    this.whoIsLoggedIn();
  }

  whoIsLoggedIn = () => {
    this.setState({ errorMessage: null, loading: true });
    APICall("api/users/profile", "GET", {})
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ loggedInUser: response.data });
          this.init(this.state.page, this.state.pageSize);
        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Refresh or try again after sometime",
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage:
            "Your request generated an error. Please check your connection",
          loading: false,
        });
      });
  };

  toggleActive = () => {
    this.setState({ errorMessage: null, toggleLoading: true });
    APICall(
      "api/users/ToggleActive/" + this.state.data.id.toString(),
      "POST",
      {}
    )
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ toggleLoading: false });
          Toastr("success", "Request successful");
        } else {
          Toastr(
            "error",
            "Your request generated an error. Refresh or try again after sometime"
          );
          this.setState({ toggleLoading: false });
        }
      })
      .catch((error) => {
        Toastr(
          "error",
          "Your request generated an error. Please check your connection"
        );
        this.setState({
          toggleLoading: false,
        });
      });
  };

  init() {
    this.setState({
      errorMessage: null,
      loading: true,
    });

    var endpoint = "";
    if (this.props.userId) {
      endpoint = "api/users/get/" + this.props.userId;
    } else {
      endpoint = "api/users/profile";
    }

    APICall(endpoint, "GET", {})
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          var updatePayload = {
            name: response.data.name,
            phoneNumber: response.data.phoneNumber,
            vcnRegistrationNumber: response.data.vcnRegistrationNumber,
          };
          this.setState(
            {
              loading: false,
              data: response.data,
              updatePayload: updatePayload,
            },
            () => {}
          );
        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Try again after sometime",
            loading: false,
          });
        }
        console.log(data);
      })
      .catch((error) => {
        this.setState({
          errorMessage:
            "Your request generated an error. Please check your network connection",
          loading: false,
        });
      });
  }

  updateProp(e, prop) {
    var record = this.state.updatePayload;
    record[prop] = e.target.value;
    this.setState({ updatePayload: record }, () => {});
  }

  validate() {
    if (
      !this.state.updatePayload.name ||
      !this.state.updatePayload.phoneNumber
    ) {
      Toastr("error", "Name and phone number cannot be blank");
      return false;
    }
    if (this.state.updatePayload.name.trim() == "") {
      Toastr("error", "Name  cannot be blank");
      return false;
    }
    if (
      this.state.updatePayload.phoneNumber.trim() == "" ||
      this.state.updatePayload.phoneNumber.trim().length < 7
    ) {
      Toastr("error", "Add a valid mobile number");
      return false;
    }
    return true;
  }

  submit() {
    if (!this.validate()) return;
    this.setState({ submitLoading: true });
    var requestPayload = this.state.updatePayload;
    requestPayload.id = this.state.data.id;
    APICall("api/users/update", "POST", requestPayload)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ submitLoading: false });
          Toastr("success", "User account updated");
          this.context.addItem("user", response.data);
        } else {
          Toastr("error", response.message);
          this.setState({ submitLoading: false });
        }
      })
      .catch((error) => {
        Toastr(
          "error",
          "Your request generated an error. Please check your network connection"
        );
        this.setState({ submitLoading: false });
      });
  }

  changePassword() {
    if (
      !this.state.newPassword ||
      !this.state.newPasswordConfirmation ||
      this.state.newPassword.trim() == "" ||
      this.state.newPasswordConfirmation.trim() == ""
    ) {
      return;
    }
    if (this.state.newPassword != this.state.newPasswordConfirmation) {
      Toastr("error", "Your new password and confirmation does not match");
      return;
    }

    this.setState({ passwordLoading: true });

    APICall("api/users/changepassword", "POST", {
      newPassword: this.state.newPassword,
    })
      .then((data) => {
        var response = data.data;
        if (response.status == "success") {
          Toastr("success", "Password changed, you will be logged out");
          setTimeout(() => {
            window.location.href = "/logout";
          }, 2000);
          this.setState({ newPassword: "", newPasswordConfirmation: "" });
        } else {
          Toastr("error", response.message);
          this.setState({ passwordLoading: false });
        }
      })
      .catch((error) => {
        Toastr(
          "error",
          "Your request generated an error. Please check your network connection"
        );
        this.setState({ passwordLoading: false });
      });
  }

  render() {
    return this.state.errorMessage ? (
      <PageError action={this.init} />
    ) : this.state.loading ? (
      <div className="px-4">
        <Spinner />{" "}
      </div>
    ) : (
      <form className="form">
        <div className="card-body pt-4 mt-4 user-bio-form">
          <div className="form-group row d-none">
            <label className="col-xl-3 col-lg-3 col-form-label text-right"></label>
            <div className="col-lg-9 col-xl-6">
              <div
                className={
                  this.state.hovered
                    ? "image-input image-input-outline hovered"
                    : "image-input image-input-outline"
                }
                id="kt_profile_avatar"
              >
                {this.state.profileImageloading ? (
                  <img
                    src={Constants.subfolder + "/assets/images/loading.gif"}
                  />
                ) : (
                  <img
                    src={Functions.getUserPhotoUrl(
                      this.state.data.id.toString()
                    )}
                  />
                )}
                <input
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  title="change display image"
                  id="filetrigger"
                  onChange={(e) => this.changeDisplayPhoto(e)}
                  onMouseEnter={() => this.setState({ hovered: true })}
                  onMouseLeave={() => this.setState({ hovered: false })}
                />
              </div>
            </div>
          </div>
          <div className="form-group row d-none">
            <label className="col-xl-3 col-lg-3 col-form-label text-right"></label>
            <div className="col-md-6 col-lg-4 col-xl-9">
              <div className="checkbox-inline">
                {this.state.data.effectiveRole != "client" ? (
                  <span
                    className={
                      "badge badge-lg badge-inline " +
                      (this.state.data.effectiveRole == "admin"
                        ? "badge-danger"
                        : "badge-info")
                    }
                  >
                    {this.state.data.effectiveRole}
                  </span>
                ) : null}

                {this.state.loggedInUser.effectiveRole == "admin" ? (
                  <>
                    <span
                      className="ml-4 mr-4 text-muted"
                      style={{ opacity: "0.25" }}
                    >
                      {" "}
                      | {this.state.toggleLoading ? <Spinner size="1" /> : null}
                    </span>

                    <label className="checkbox checkbox-danger">
                      <input
                        type="checkbox"
                        defaultChecked={this.state.data.isDisabled}
                        onChange={() => this.toggleActive()}
                      />{" "}
                      Block this user <span></span>
                    </label>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-right">
              {" "}
              Name
            </label>
            <div className="col-md-6 col-lg-4 col-xl-6">
              <input
                className="form-control  form-control-solid"
                type="text"
                defaultValue={this.state.data.name}
                onChange={(e) => this.updateProp(e, "name")}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-right">
              {" "}
              Email
            </label>
            <div className="col-md-6 col-lg-4 col-xl-6">
              <div className="input-icon input-icon-right">
                <input
                  className="form-control  form-control-solid"
                  type="email"
                  readOnly
                  defaultValue={this.state.data.email}
                />
                <span>
                  <i className="mdi mdi-lock-outline"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-right">
              {" "}
              Phone
            </label>
            <div className="col-md-6 col-lg-4 col-xl-6">
              <div className="input-icon input-icon-right">
                <input
                  className="form-control  form-control-solid"
                  type="tel"
                  readOnly
                  defaultValue={this.state.data.phoneNumber}
                  onChange={(e) => this.updateProp(e, "phoneNumber")}
                />
                <span>
                  <i className="mdi mdi-lock-outline"></i>
                </span>
              </div>
            </div>
          </div>

          {["vet", "vetsupervisor"].includes(this.state.data.userRole) && (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                {" "}
                VCN Number
              </label>
              <div className="col-md-6 col-lg-4 col-xl-6">
                <div className="input-icon input-icon-right">
                  <input
                    className="form-control  form-control-solid"
                    type="text"
                    defaultValue={this.state.data.vcnRegistrationNumber}
                    onChange={(e) =>
                      this.updateProp(e, "vcnRegistrationNumber")
                    }
                  />
                </div>
              </div>
            </div>
          )}

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-right"></label>
            <div className="col-md-6 col-lg-4 col-xl-6">
              {this.state.submitLoading ? (
                <div className="text-center">
                  <Spinner size="2" />{" "}
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    className="btn btn-success btn-block"
                    onClick={() => this.submit()}
                  >
                    Update Data
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    );
  }
}
