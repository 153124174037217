import React, { Component } from "react";
import CenterBlock from "../Components/CenterBlock";
import AddWebScripts2 from "../Components/Layouts/AddWebScripts2";
import Constants from "../Utils/Constants";
import Spinner from "../Utils/Spinner";
import Logo from "../Logo";
import "../sass/landing-page2.scss";
import { Link } from "react-router-dom";

export default class LandingPage2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.valueChainItem = this.valueChainItem.bind(this);
    this.menuLink = this.menuLink.bind(this);
  }

  componentDidMount() {
    /* if (
      process.env.REACT_APP_LANDING_PAGE &&
      process.env.REACT_APP_LANDING_PAGE.trim() != ""
    ) {
      window.location.href = process.env.REACT_APP_LANDING_PAGE;
    }*/
  }

  pageImage(image) {
    return `${Constants.subfolder}/landing-page/img/${image}`;
  }

  menus = [
    {
      label: "Home",
      url: "#",
      // children: [],
    },
    {
      label: "Register",
      url: "#",
      children: [
        { label: "Supplier Registration", url: "/register/distributor" },
        { label: "Retailer Registration", url: "/register/regular" },
      ],
    },
    {
      label: "Links",
      url: "#",
      children: [
        {
          label: "Lagos State Ministry of Agric",
          url: "https://agriculture.lagosstate.gov.ng",
        },
        {
          label: "OIE (World Animal health Organization)",
          url: "https://www.oie.int/en/home",
        },
        {
          label: "FAO (World Food Organizaiton)",
          url: "http://www.fao.org/home/en/",
        },
        {
          label: "WHO",
          url: "https://www.who.int/",
        },
        {
          label: "Veterinary Council of Nigeria",
          url: "https://www.vcn.gov.ng/",
        },
        {
          label: "Nigerian Veterinary Medical Association",
          url: "https://www.nvma.org.ng/",
        },
      ],
    },
    {
      label: "Login",
      url: "/login",
    },
  ];

  menuLink(menu) {
    if (menu.url.startsWith("http")) {
      return (
        <a href={menu.url} target="_blank">
          {menu.label}
        </a>
      );
    } else {
      return <Link to={menu.url}>{menu.label}</Link>;
    }
  }

  renderMenu() {
    return (
      <ul className="menu">
        {this.menus.map((menu, index) => (
          <li
            className={
              menu.children && menu.children.length > 0 ? "has-dropdown" : ""
            }
            key={index}
          >
            {this.menuLink(menu)}
            {menu.children && menu.children.length && (
              <ul>
                {menu.children.map((childMenu, childIndex) => (
                  <li key={childIndex}>{this.menuLink(childMenu)}</li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    );
  }

  reverseBox({ title, body }) {
    return (
      <div className="col-lg-4 col-sm-6">
        <div
          className="single-techinical text-center"
          data-aos="fade-right"
          style={{ padding: "30px 25px 20px" }}
        >
          <h4>{title}</h4>
          <p className="text-white">{body}</p>
        </div>
      </div>
    );
  }

  valueChainItem({ title1, body1, image1, title2, body2, image2 }) {
    return (
      <div className={`row mb-60`}>
        <div className="col-md-6 px-5">
          {image1 && (
            <div
              className="value-chain-image"
              style={{ backgroundImage: `url(${this.pageImage(image1)})` }}
            ></div>
          )}
          <div
            className={`notification-content ${image1 ? "" : "mt-0"}`}
            data-aos="fade-up"
          >
            <h6 className="theme-color-secondary mb-0">VALUE CHAIN</h6>
            <h2>{title1}</h2>
            <p>{body1}</p>
          </div>
        </div>
        {image2 && (
          <div className="col-md-6 px-5">
            <div
              className="value-chain-image"
              style={{ backgroundImage: `url(${this.pageImage(image2)})` }}
            ></div>
            <div className="notification-content" data-aos="fade-up">
              <h6 className="theme-color-secondary mb-0">VALUE CHAIN</h6>
              <h2>{title2}</h2>
              <p>{body2}</p>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <>
        <AddWebScripts2 />
        <div className="wrapper landing-page-2">
          {/* Start Header */}
          <header id="header" className="header-area absulate-header">
            <div className="container">
              <div className="row">
                <div className="col-9 col-md-3">
                  {/* Start site logo */}
                  <div className="site-logo">
                    <a href="index.html">
                      <Logo reverse={true} style={{ width: "-webkit-fill-available" }} />
                    </a>
                  </div>
                  {/* End site logo */}
                </div>
                <div className="col-3 col-md-9">
                  {/* Start main navigation */}
                  <div className="main-menu-wrap">
                    <nav className="proone-nav">{this.renderMenu()}</nav>
                  </div>
                  {/* Mobile Menu Active here. Don't Remove it */}
                  <div className="mobile-menu-area" />
                  {/* End main navigation */}
                </div>
              </div>
            </div>
          </header>
          <Link
            to="/verify-certificate"
            className="slideInBottom cssanimation animated btn btn-rounded btn-lg angleindouble color-1 mb-2 float-verify main-verifybutton"
          >
            VERIFY A CERTIFICATE
          </Link>
          {/* End Header */}
          {/* Start Hero Area */}
          <div
            className="hero-area style-1"
            style={{ backgroundImage: `url(${this.pageImage("Clip.png")})` }}
          >
            <div className="hero-content-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    {/* Start hero content */}
                    <div className="hero-content">
                      <h1>The pathway to meat export in Nigeria</h1>
                      <p>
                        Proteintrail is digitalizing the entire process to facilitate and
                        strengthen due diligence in the systems enabling meat export from the ranch,
                        approved abattoirs, Nigerian customs at all international airports,
                        and foreign off-takers, all within our patented app.
                      </p>
                      <Link
                        to="/verify-certificate"
                        className="text-white font-weight-bold color-1 mb-2"
                      >
                        VERIFY A CERTIFICATE
                      </Link>
                    </div>
                    {/* End hero content */}
                  </div>
                  <div className="col-md-6">
                    {/* Start hero right */}
                    <div className="hero-right"></div>
                    {/* End hero right */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Hero Area */}

          <div className="feature-area pt-110pb-68" id="about-us">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  {/* Start section title */}
                  <div className="section-title width-80" data-aos="fade-up">
                    <h2>The ProteinTrail Idea</h2>
                    <p>
                      Proteintrail is a tech startup initially established to sanitise Nigeria's meat value chain.
                      The modus operandi is to use advances in information and communication technology,
                      including artificial intelligence, to digitise and coordinate the various systems
                      in production, distribution, and supply within the value chain.
                    </p>
                    {/* <p>
                      As the systems were developed, it became clear that other
                      value chains like aquatic animal, sheep and goat, pork,
                      camel etc (all collectively described as first class
                      protein sources) including fruits and vegetables could
                      benefit immensely from our progressive activity. To this
                      end, the vision received an upgrade and so did our
                      standard operating procedure.
                    </p> */}
                  </div>
                  {/* End section title */}
                </div>
              </div>
              <div className="row">
                <div className="col col-md-4">
                  {/* Start single feature */}
                  <div
                    className="single-feature animation-1"
                    data-aos="fade-right"
                  >
                    <img src={this.pageImage("feature-1.png")} alt="Feature" />
                    <h2>Inclusion</h2>
                    <p>
                      Proteintrail offers a seamless electronic mechanism to all stakeholders within the meat value chain to monitor the consignment of raw and processed meat.
                    </p>
                  </div>
                  {/* End single feature */}
                </div>
                <div className="col col-md-4">
                  {/* Start single feature */}
                  <div className="single-feature" data-aos="fade-left">
                    <img src={this.pageImage("feature-2.png")} alt="Feature" />
                    <h2>Transparency</h2>
                    <p>
                      Foreign off-takers have been unable to trade with Nigeria because our practice lacks transparency.
                      We have made it possible for foreign off-takers to monitor the whole process of meat export on the App,
                      from the birth certificates to the fattening stages( 12, 24 or 36 months), plus real-time vaccination records applied to the cattle.
                    </p>
                  </div>
                  {/* End single feature */}
                </div>

                <div className="col col-md-4">
                  {/* Start single feature */}
                  <div className="single-feature" data-aos="fade-up">
                    <img src={this.pageImage("feature-3.png")} alt="Feature" />
                    <h2>Quality Control</h2>
                    <p>
                      Slaughtered animals are required by law to pass through
                      veterinary and other fitness checks by trained
                      veterinarians before their meat can be passed to the
                      public for consumption.
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>

          {/* Start Technical Space Area */}
          <div className="techinical-area pt-110">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-8">
                  {/* Start section title */}
                  <div
                    className="section-title white text-center"
                    data-aos="fade-up"
                  >
                    <h2>
                      Ante-Mortem {"&"} <br />
                      Post-Mortem Inspection
                    </h2>
                    <p>
                      When animals pass through the stages of inspection, they
                      are usually issued some form of legal pass which
                      traditionally happens in the form of a certificate
                      (handled by the merchants) or some kind of body marking or
                      a combination of either of these to varying degrees
                      depending on jurisdiction. Proteintrail automates these
                      into a seamless one stop process having the following
                      effect among other things:
                    </p>
                  </div>
                  {/* End section title */}
                </div>
              </div>
              <div className="row">
                <this.reverseBox
                  title={"Central Database"}
                  body={
                    "Centralizing a high security database for use in policy making, epidemiological studies and so on."
                  }
                />
                <this.reverseBox
                  title={"Traceback"}
                  body={
                    "Assisting with traceback in the event of food borne disease outbreaks especially when there is a need for recalls."
                  }
                />
                <this.reverseBox
                  title={"Auditing"}
                  body={
                    "Enhance the auditing and investigation processes during HACCP related investigations."
                  }
                />
                <this.reverseBox
                  title={"Professionalism"}
                  body={
                    "Raise the index of responsibility and expectation of excellence by veterinary professionals"
                  }
                />
                <this.reverseBox
                  title={"Accountability"}
                  body={
                    "Increase the level of accountability of Government agencies vested with the responsibility of food safety."
                  }
                />
                <this.reverseBox
                  title={"Customer Confidence"}
                  body={
                    "Increase consumer confidence in the capabilities of government to protect public health."
                  }
                />
              </div>
            </div>
          </div>
          {/* End Technical Space Area */}
          {/* Start Notification Area */}
          {/* <div className="notification-area pt-120pb-115">
            <div className="container">
              <this.valueChainItem
                image1="cow-calf.jpg"
                title1="Red meat/Beef, Milk etc"
                body1="According to reliable statistics from the Lagos State Ministry of Agriculture, over 6000 herds of cattle are slaughtered in Lagos alone everyday. The processes described above works majorly for cattle. Milk is another value chain that requires inspection. This would be for raw milk that is sourced within Nigeria. Our ports of call will therefore be the major milk companies eg WAMCO. Inspection of raw milk as well as processes for HACCP compliance protocols based on a pre agreed template will be carried out and certificates issued based on compliance."
                image2="goat.jpg"
                title2="Goat Meat"
                body2=""
              />

              <this.valueChainItem
                image1="sheep.jpg"
                title1={<>Sheep Meat</>}
                body1={
                  <>
                    <strong>Mutton and Lamb</strong>
                    <br />
                  </>
                }
                image2="pig.jpg"
                title2="Pork Meat"
                body2="Sadly no class A facility exists as far as we know in the state where pig is processed to pork. At best, facilities are class C. So until investment has been made in this area to develop this value chain, we may not be able to certify pig meat confidently"
              />

              <this.valueChainItem
                image1="fish2.jpg"
                title1={<>Fish</>}
                body1={
                  <>
                    <strong>Including other aquatic products</strong>
                    <br />
                    This follows a very different value chain route than meat.
                    The sources include
                    <ul>
                      <li>
                        <i className="icofont-check"></i>Importation through the
                        air and sea ports
                      </li>
                      <li>
                        <i className="icofont-check"></i>Fishing activities by
                        artisanal fish farmers
                      </li>
                      <li>
                        <i className="icofont-check"></i>Fish and other aquatic
                        animal culture activity by subsistence and large scale
                        fish farmers
                      </li>
                    </ul>
                    Certification can usually be done via cold room inspection
                    and batches are certified in bulk following sample
                    collection and on site veterinary inspection.
                  </>
                }
                image2="poultry.jpg"
                title2="Poultry Meat, Eggs etc."
                body2={
                  <>
                    Certificates and codes shall be issued in respect of poultry
                    products that are slaughtered in mechanized poultry
                    facilities. Currently there are no veterinary officer
                    postings to such facilities so Protein trail will have to
                    press it upon government to post officers to such facilities
                    after properly inumerating. Where we can thence facilitate
                    certification. <br />
                    <br />
                    Eggs sold out of farms should also be certified as fit for
                    human consumption as well as drug or antibiotic free. This
                    is currently not carried out on farms by state government
                    and Proteintrail can impress it upon government to do so.
                  </>
                }
              />

              <this.valueChainItem
                image1={null}
                title1={<>Other Exotic Meat</>}
                body1={
                  <>
                    <strong>Bush meat, etc</strong>
                    <br />
                    This would include imports of all kinds as well as other
                    meats not include in the above listed categories. Government
                    will also have to find a way to certify them.
                  </>
                }
              />
            </div>
          </div> */}
          {/* End Notification Area */}
          {/* Start Video Area */}
          {null && (
            <div
              id="video"
              className="video-area"
              style={{ backgroundImage: "url(img/video.svg)" }}
              data-aos="fade-up"
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-7 col-lg-8">
                    {/* start section tilte */}
                    <div className="section-title white text-center">
                      <h2>Description With Video</h2>
                      <p>
                        Delay rapid joy share allow age manor six. Went why far
                        saw many knew. Exquisite excellent son gentleman
                        acuteness her.
                      </p>
                    </div>
                    {/* start section tilte */}
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    {/* start video */}
                    <div className="video">
                      <img src="img/video.jpg" alt="" />
                      <a
                        className="venobox vbox-item"
                        data-gall="gall-video"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="http://vimeo.com/75976293"
                      >
                        <i className="icofont-play-alt-2" />
                      </a>
                    </div>
                    {/* end video */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* End Video Area */}
          {/* Start Testimonial Area */}
          {null && (
            <div
              id="review"
              className="testimonial-area pt-240 pb-140"
              data-aos="fade-up"
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    {/* Start section title */}
                    <div className="section-title text-center">
                      <h2>Our Clients Reviews And Testimonials</h2>
                      <p>
                        Delay rapid joy share allow age manor six. Went why far
                        saw many knew. Exquisite excellent son gentleman
                        acuteness her.
                      </p>
                    </div>
                    {/* End section title */}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {/* Start Testimonial Wrap */}
                    <div className="testimonial-wrap">
                      {/* Start single testimonial */}
                      <div className="single-testimonial text-center">
                        <div className="testi-img">
                          <img src="img/testi-1.png" alt="" />
                        </div>
                        <div className="testi-content">
                          <i className="icofont-quote-left" />
                          <p>
                            Delay rapid joy share allow age manor six. Went why
                            far saw many knew. Exquisite excellent son gentleman
                            acuteness her.
                          </p>
                          <ul>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                          </ul>
                          <h5>Christopher</h5>
                          <h6>July 28, 2020</h6>
                        </div>
                      </div>
                      {/* End single testimonial */}
                      {/* Start single testimonial */}
                      <div className="single-testimonial text-center">
                        <div className="testi-img">
                          <img src="img/testi-2.png" alt="" />
                        </div>
                        <div className="testi-content">
                          <i className="icofont-quote-left" />
                          <p>
                            Delay rapid joy share allow age manor six. Went why
                            far saw many knew. Exquisite excellent son gentleman
                            acuteness her.
                          </p>
                          <ul>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                          </ul>
                          <h5>Mark Rosas</h5>
                          <h6>August 28, 2019</h6>
                        </div>
                      </div>
                      {/* End single testimonial */}
                      {/* Start single testimonial */}
                      <div className="single-testimonial text-center">
                        <div className="testi-img">
                          <img src="img/testi-3.png" alt="" />
                        </div>
                        <div className="testi-content">
                          <i className="icofont-quote-left" />
                          <p>
                            Delay rapid joy share allow age manor six. Went why
                            far saw many knew. Exquisite excellent son gentleman
                            acuteness her.
                          </p>
                          <ul>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                          </ul>
                          <h5>Michael Jonson</h5>
                          <h6>Decembor 17, 2020</h6>
                        </div>
                      </div>
                      {/* End single testimonial */}
                      {/* Start single testimonial */}
                      <div className="single-testimonial text-center">
                        <div className="testi-img">
                          <img src="img/testi-1.png" alt="" />
                        </div>
                        <div className="testi-content">
                          <i className="icofont-quote-left" />
                          <p>
                            Delay rapid joy share allow age manor six. Went why
                            far saw many knew. Exquisite excellent son gentleman
                            acuteness her.
                          </p>
                          <ul>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                            <li>
                              <i className="icofont-star" />
                            </li>
                          </ul>
                          <h5>Richard Roy</h5>
                          <h6>July 28, 2020</h6>
                        </div>
                      </div>
                      {/* End single testimonial */}
                    </div>
                    {/* End Testimonial Wrap */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* End Testimonial Area */}
          {/* Start News Leater Area */}
          {null && (
            <div className="newsleater-area mb-120" data-aos="fade-up">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    {/* Start section title */}
                    <div className="section-title">
                      <h2>Latest Update And New Offers Notification</h2>
                      <p>
                        Delay rapid joy share allow age manor six. Went why far
                        saw many knew. Exquisite excellent son gentleman
                        acuteness her.
                      </p>
                    </div>
                    {/* End section title */}
                    {/* Start form */}
                    <form
                      action="#"
                      method="post"
                      className="subscribe-form watch"
                    >
                      <input
                        name="email"
                        className="widget-input"
                        placeholder="Email address"
                        type="email"
                      />
                      <button type="submit" className="widget-sbtn">
                        SUBSCRIBE
                      </button>
                    </form>
                    {/* End form */}
                  </div>
                  <div className="col-md-6" />
                </div>
              </div>
            </div>
          )}
          <footer className="footer-area pt-65">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  {/* Start footer logo */}
                  <div className="footer-logo text-center">
                    <a href="#">
                      <Logo reverse={true} style={{ height: "90px" }} />
                    </a>
                  </div>
                  {/* End footer logo */}
                  {/* Start footer navigation */}
                  <div className="widget footer-menu text-center">
                    <ul>
                      <li className="li">
                        <a href="/">HOME</a>
                      </li>
                      <li className="li">
                        <Link to="/login">LOGIN</Link>
                      </li>
                      <li className="li">
                        <a target="blank" href="/proteintrail_privacy_policy.pdf">PRIVACY POLICY</a>
                      </li>
                      <li className="li">
                        <a target="blank" href="https://www.who.int/">WHO</a>
                      </li>
                    </ul>
                  </div>
                  {/* End footer navigation */}
                  {/* Start footer social */}
                  <div className="footer-social">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="icofont-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="icofont-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="icofont-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="icofont-google-plus" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="icofont-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* End footer social */}
                </div>
              </div>
            </div>
            {/* Start-copy-right */}
            <div className="copyright pt-18pb-5">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="copy-right">
                      <p>
                        © {new Date().getFullYear()}{" "}
                        {process.env.REACT_APP_NAME}. All Rights Reserved
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End-copy-right */}
          </footer>
          {/* End Footer Area */}
        </div>
      </>
    );
  }
}
