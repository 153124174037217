import React, { Component, useEffect, useState } from "react";
import Constants from "../../Utils/Constants";

export default function AddAppScripts() {
  const jsScripts = [
    "/assets/vendors/js/vendor.bundle.base.js",
    "/assets/vendors/chart.js/Chart.min.js",
    "/assets/vendors/jquery-circle-progress/js/circle-progress.min.js",
    "/assets/js/off-canvas.js",
    "/assets/js/hoverable-collapse.js",
    "/assets/js/misc.js",
    "/assets/js/dashboard.js",
  ];

  const stylesheets = [
    "/assets/vendors/css/vendor.bundle.base.css",
    "/assets/vendors/bootstrap-datepicker/bootstrap-datepicker.min.css",
    "/assets/css/style.css",
    "/assets/css/custom.css",
  ];
  const loadedScripts = [];
  const loadedStyles = [];

  const loadScripts = function () {
    loadStyles();
    jsScripts.forEach((scriptUrl) => {
      const script = document.createElement("script");
      script.src = Constants.subfolder + scriptUrl;
      script.async = true;
      document.body.appendChild(script);
      loadedScripts.push(script);
    });
  };

  ///loadStyles is called in loadScripts too
  const loadStyles = function () {
    stylesheets.forEach((css) => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = Constants.subfolder + css;
      document.head.appendChild(link);
      loadedStyles.push(link);
    });
  };

  const unloadScripts = function () {
    loadedScripts.forEach((script) => {
      document.body.removeChild(script);
    });
    loadedStyles.forEach((css) => {
      document.head.removeChild(css);
    });
  };

  useEffect(() => {
    document.body.classList.add("notvisible");
    loadScripts();

    setTimeout(() => {
      document.body.classList.remove("notvisible");
    }, 2000);

    return () => unloadScripts();
  }, []);

  return null;
}
