import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../Logo";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import Constants from "../../Utils/Constants";
import PublicWithTopStripBackground from "../../Components/Layouts/PublicWithTopStripBackground";

export default class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {},
      errorMessage: null,
      loading: false,
      redirectUrl: null,
    };
  }

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
    if (!this.props.match.params.code) {
      window.location.href = "/login";
    }
  }

  updateProp(e, prop, value) {
    var record = this.state.payload;
    record[prop] = value ? value : e.target.value;
    this.setState({ payload: record });
  }

  sendToServer() {
    var payload = {
      data: this.state.payload.password,
    };
    APICall(
      `api/users/register/password/${this.props.match.params.code}`,
      "POST",
      payload
    )
      .then((data) => {
        if (data.data.isSuccess) {
          this.setState({
            redirectUrl: "/set-password-successful",
          });
        } else {
          this.setState({ errorMessage: data.data.message, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  submit() {
    if (
      !this.state.payload.password ||
      this.state.payload.password.trim() == ""
    ) {
      this.setState({ errorMessage: "Supply a new password to continue" });
      return;
    }

    if (
      !this.state.payload.passwordConfirmation ||
      this.state.payload.passwordConfirmation.trim() == ""
    ) {
      this.setState({ errorMessage: "Confirm your password to continue" });
      return;
    }

    if (this.state.payload.password.length < 8) {
      this.setState({
        errorMessage: "Supply a password of at least 8 characters",
      });
      return;
    }

    if (
      this.state.payload.password != this.state.payload.passwordConfirmation
    ) {
      this.setState({
        errorMessage: "Your password and confirmation needs to match",
      });
      return;
    }

    this.setState({ errorMessage: null, loading: true }, () => {
      this.sendToServer();
    });
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <PublicWithTopStripBackground
        children={
          <div className="col-lg-4 mx-auto">
            <div
              className="auth-form-light text-left p-5 shadow"
              style={{ borderRadius: "15px" }}
            >
              <div className="brand-logo">
                <Logo />
              </div>
              <h4>
                <span className="text-darker">Set account password</span>
              </h4>
              <h6 className="font-weight-light">
                Choose a secure password of at least 8 characters for your
                account.
              </h6>
              <form className="pt-3" autoComplete="newpassword">
                <div className="form-group">
                  <input
                    onChange={(e) => this.updateProp(e, "password")}
                    type="password"
                    className="form-control form-control-sm"
                    placeholder="New password"
                  />
                </div>
                <div className="form-group">
                  <input
                    onChange={(e) => this.updateProp(e, "passwordConfirmation")}
                    type="password"
                    className="form-control form-control-sm"
                    placeholder="Confirm password"
                  />
                </div>

                {this.state.errorMessage ? (
                  <div className="mb-3">
                    <div className="alert alert-danger small" role="alert">
                      {this.state.errorMessage}
                    </div>
                  </div>
                ) : null}

                <div className="mt-3">
                  {this.state.loading ? (
                    <button
                      type="button"
                      className="btn btn-block btn-link text-success btn-lg font-weight-medium auth-form-btn"
                    >
                      <Spinner size="1" />{" "}
                    </button>
                  ) : (
                    <button
                      id="confirm-phone-button"
                      type="button"
                      onClick={() => this.submit()}
                      className="btn-rounded btn btn-block btn-success btn-lg font-weight-medium auth-form-btn"
                    >
                      SUBMIT
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        }
      />
    );
  }
}
