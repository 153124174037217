import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../Logo";
import APICall from "../../Utils/APICall";
import SpinnerButton from "../../Utils/SpinnerButton";
import Constants from "../../Utils/Constants";
import Select from "react-select";
import AddAppScripts from "../../Components/Layouts/AddAppScripts";
import { DataContext } from "../../Contexts/DataContexts";

export default class VetCheckin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRecord: {},
      errorMessage: null,
      loading: false,
      currentCheckin: {},
      abattoirs: [],
    };

    this.submit = this.submit.bind(this);
  }

  static contextType = DataContext;

  componentDidMount() {
    const { globalState, addItem } = this.context;
    this.setState({ profile: globalState?.data })
    const { state, country } = globalState?.user
    this.listAbattoirs(state, country);
    this.getLastCheckin();
  }


  listAbattoirs(state, country) {
    APICall(`api/abattoirs/get/${country}/${state}`, "get")
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          response.data.forEach((element) => {
            element.label = element.name;
            element.value = element.id;
          });

          this.setState({ abattoirs: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getLastCheckin() {
    APICall("api/vets/lastcheckin", "get")
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          if (response.data) {
            response.data.label = response.data.name;
            response.data.value = response.data.id;
            this.setState({ currentCheckin: response.data });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(abattoir) {
    this.setState({ currentCheckin: abattoir });
  }
  submit() {
    if (!this.state.currentCheckin || !this.state.currentCheckin.id) {
      this.setState({
        errorMessage: "Please choose an abattoir to check into",
      });
      return;
    }

    this.setState({ errorMessage: null, loading: true });

    APICall(`api/vets/checkinto/${this.state.currentCheckin.id}`, "POST", {})
      .then((data) => {
        if (data.data.isSuccess) {
          this.setState({ redirectUrl: "/in/home" });
        } else {
          this.setState({ errorMessage: data.data.message, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <div
        className="container-fluid page-body-wrapper full-page-wrapper"
        style={{
          backgroundImage: `url(${Constants.subfolder}/assets/images/background.jpg)`,
          backgroundSize: "auto 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "-300px 0px",
        }}
      >
        <AddAppScripts />
        <div className="content-wrapper d-flex align-items-center auth text-center login-gradient-bg">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div
                className="auth-form-light text-left p-5 shadow"
                style={{ borderRadius: "15px" }}
              >
                <div className="brand-logo text-center">
                  <Logo style={{ height: "30px", width: "auto" }} />
                </div>
                <h4 className="text-darker text-center mt-5">
                  Check into an abattoir
                </h4>

                <form className="pt-3">
                  <div className="form-group">
                    <Select
                      options={this.state.abattoirs}
                      value={this.state.currentCheckin}
                      onChange={(option) => this.handleChange(option)}
                    />
                  </div>

                  {this.state.errorMessage ? (
                    <div className="mb-3">
                      <div className="alert alert-danger small" role="alert">
                        {this.state.errorMessage}
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-3 text-center">
                    <SpinnerButton
                      className="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn btn-rounded"
                      label="Check in"
                      loading={this.state.loading}
                      onClick={() => this.submit()}
                    />
                  </div>
                  <div className="mt-3 text-center">
                    <Link
                      to="/in/home"
                      className="btn btn-block btn-link btn-lg font-weight-medium auth-form-btn btn-rounded"
                    >
                      Continue without checkin
                    </Link>
                  </div>
                  <div className="my-2 text-center"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
      </div>
    );
  }
}
