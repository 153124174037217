import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Utils/Spinner";
import APICall from "../Utils/APICall";

export default class VerifyCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loading: false,
      redirectUrl: null,
      certificateNumber: null,
    };
  }

  componentDidMount() {}

  loadCertificate() {
    this.setState({ loading: true });
    const _certificateNumber = this.state.certificateNumber?.trim();
    APICall(`api/certificates/verify/${_certificateNumber}`, "GET")
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          this.setState({
            redirectUrl: `/view-certificate/${_certificateNumber}?returnUrl=${this.props.returnUrl}`,
          });
        } else {
          this.setState({ errorMessage: response.message, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <>
        <h6 className="font-weight-light">
          Enter certificate number below to continue.
        </h6>
        <form className="pt-2">
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              onChange={(e) =>
                this.setState({ certificateNumber: e.target.value })
              }
            />
          </div>

          {this.state.errorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {this.state.errorMessage}
              </div>
            </div>
          ) : null}

          <div className="mt-3">
            {this.state.loading ? (
              <button
                type="button"
                className="btn btn-block btn-light btn-lg font-weight-medium auth-form-btn"
              >
                <Spinner size="1" />{" "}
              </button>
            ) : (
              <button
                id="confirm-phone-button"
                type="button"
                onClick={() => this.loadCertificate()}
                className="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn"
              >
                Verify Now
              </button>
            )}
          </div>
          <div className="text-center mt-4 font-weight-light"></div>
        </form>
      </>
    );
  }
}
