import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../Logo";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import SpinnerButton from "../../Utils/SpinnerButton";
import Constants from "../../Utils/Constants";
import { DataContext } from "../../Contexts/DataContexts";
import Functions from "../../Utils/Functions";
import AddAppScripts from "../../Components/Layouts/AddAppScripts";
import Toastr from "../../Utils/Toastr";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRecord: {},
      errorMessage: null,
      loading: false,
    };

    this.submit = this.submit.bind(this);
  }

  static contextType = DataContext;

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
  }

  updateProp(e, prop) {
    var record = this.state.newRecord;
    record[prop] = e.target.value;
    this.setState({ newRecord: record });
  }

  doRedirects(loginResponse) {
    if (
      loginResponse.data == "vet" ||
      loginResponse.data == "operationsassistant"
    ) {
      this.setState({ redirectUrl: "/in/checkin" });
    } else {
      if (loginResponse.redirectUrl) {
        window.location.href = loginResponse.redirectUrl;
      } else {
        this.setState({ redirectUrl: "/in/home" });
      }
    }
  }

  submit() {
    if (!this.state.newRecord.emailOrPhone || !this.state.newRecord.password) {
      this.setState({
        errorMessage: "Please enter your email and password to login",
      });
      return;
    }

    this.setState({ errorMessage: null, loading: true });
    let payload = {
      username: this.state.newRecord.emailOrPhone,
      password: this.state.newRecord.password,
    };
    APICall("api/users/login", "POST", payload)
      .then((data) => {
        if (data.data.isSuccess) {
          new Functions().whoIsLoggedIn((u, e) => {
            this.context.addItem("user", u);
            this.doRedirects(data.data);
          }, true);
        } else {
          this.setState({ errorMessage: data.data.message, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <div
        className="container-fluid page-body-wrapper full-page-wrapper"
        style={{
          backgroundImage: `url(${Constants.subfolder}/assets/images/background.jpg)`,
          backgroundSize: "auto 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "-300px 0px",
        }}
      >
        <AddAppScripts />
        <div className="content-wrapper d-flex align-items-center auth text-center login-gradient-bg">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div
                className="auth-form-light text-left p-5 shadow"
                style={{ borderRadius: "15px" }}
              >
                <div className="brand-logo text-center">
                  <Logo style={{width: "-webkit-fill-available" }} />
                </div>
                <h4 className="text-darker text-center mt-5">
                  Sign in to continue.
                </h4>

                <form className="pt-3">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-sm"
                      placeholder="Email or Phone number"
                      onChange={(e) => this.updateProp(e, "emailOrPhone")}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-sm"
                      placeholder="Password"
                      onChange={(e) => this.updateProp(e, "password")}
                    />
                  </div>
                  {this.state.errorMessage ? (
                    <div className="mb-3">
                      <div className="alert alert-danger small" role="alert">
                        {this.state.errorMessage}
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-3 text-center">
                    <SpinnerButton
                      className="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn btn-rounded"
                      label="SIGN IN"
                      loading={this.state.loading}
                      onClick={() => this.submit()}
                    />
                  </div>
                  <div className="my-2 text-center">
                    <a
                      href={Constants.subfolder + "/password-recovery"}
                      className="auth-link text-black"
                    >
                      Forgot password?
                    </a>
                  </div>
                  <div className="text-center mt-4 font-weight-semibold small">
                    <hr /> If you don't have an account and you are a meat
                    supplier{" "}
                    <Link to={"/register/distributor"} className="text-primary">
                      Please register here
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
      </div>
    );
  }
}

export default Login;
