import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../Logo";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "../../sass/react-phone.scss";
import PublicWithTopStripBackground from "../../Components/Layouts/PublicWithTopStripBackground";
import Tabs from "../../Utils/Tabs";
import RenderCertificate from "../../Components/RenderCertificate";
import SingleDiseaseReport from "../../Components/DiseaseReporting/SingleDiseaseReport";
import DiseaseList from "../../Components/DiseaseReporting/DiseaseList";
import RenderTag from "../../Components/RenderTag";

export default class RenderTagViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loading: true,
      redirectUrl: null,
      data: null,
      display: null,
    };
  }

  componentDidMount() {
    this.loadCertificate();
  }

  loadCertificate() {
    this.setState({ loading: true });
    APICall(
      `api/certificates/verify/${this.props.match.params.certificateCode}`,
      "GET"
    )
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          this.setState({ data: response.data, loading: false }, () => {
            this.prepareView(response.data);
          });
        } else {
          this.setState({ errorMessage: response.message, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  prepareView(certificateData) {
    const tabHeaders = [{ icon: "", text: "Certificate" }];

    const tabContents = [
      <RenderTag certificateCode={certificateData.certificate.code} />,
    ];

    if (localStorage.getItem("token")) {
      tabHeaders.push({ icon: "", text: "Condemnation History" });
      tabContents.push(
        <div className="p-4 mt-3">
          {<DiseaseList diseaseList={certificateData.diseaseReport} />}
        </div>
      );
    }

    this.setState({
      display: <Tabs headers={tabHeaders} contents={tabContents} />,
    });
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <PublicWithTopStripBackground
        children={
          <div className="col-12 col-lg-10 mx-auto">
            <div
              className="auth-form-light text-left p-5 shadow mb-5"
              style={{ borderRadius: "15px", minHeight: "100vh" }}
            >
              {true && (
                <div style={{ position: "absolute", top: "30px" }}>
                  <i
                    onClick={() =>
                      this.setState({
                        redirectUrl: this.props.history.goBack(),
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className="mdi mdi-keyboard-backspace icon-lg"
                  >
                    <small
                      style={{
                        fontSize: "35%",
                        position: "relative",
                        top: "-13px",
                      }}
                    >
                      back
                    </small>
                  </i>{" "}
                </div>
              )}
              <div className="brand-logo text-right">
                <Logo />
              </div>
              {this.state.errorMessage && (
                <div className="alert alert-danger">
                  {this.state.errorMessage}
                </div>
              )}
              {this.state.loading && <Spinner />}
              {this.state.display}
            </div>
          </div>
        }
      />
    );
  }
}
