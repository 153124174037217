import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import APICall from "../../Utils/APICall";
import DataAPI from "../../Utils/DataAPI";

export default function DiseaseList({ diseaseList, tagNumber }) {
  const [diseaseReports, setDiseaseReports] = useState([]);

  const loadDiseaseList = () => {
    APICall(`api/diseasereports/get/${tagNumber}`, "GET")
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          setDiseaseReports(response.data);
        } else {
          setDiseaseReports([]);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (diseaseList) {
      setDiseaseReports(diseaseList);
    } else {
      loadDiseaseList();
    }
  }, []);

  const DataDisplay = ({ label, data }) => {
    return (
      <div className="form-group row  mb-0">
        <div className="col">
          <strong className="text-dark small font-weight-bold mb-2">
            {label}
          </strong>
          <p className="text-dark mb-2">{data}</p>
        </div>
      </div>
    );
  };

  return (
    <div style={{ marginTop: "-10px" }}>
      <div className="alert alert-success mb-5">
        {diseaseReports.length} report(s) found for this animal
      </div>
      {diseaseReports.map((report, index) => (
        <div key={index}>
          <div className="row">
            <div className="col-12 mb-3">
              <h4 class="text-darker">
                <span className="theme-color">{report.organ}</span>{" "}
                <small className="mx-2 text-muted">/</small>
                Condemnation:{" "}
                <span
                  className={`${
                    report.condemnation == "Total"
                      ? "text-danger"
                      : report.condemnation == "Partial"
                      ? "text-warning"
                      : "text-success"
                  }`}
                >
                  {report.condemnation}
                </span>
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col">
                  <DataDisplay
                    label="Pathological Diagnosis"
                    data={report.pathologicalDiagnosis}
                  />
                </div>
                <div className="col">
                  <DataDisplay
                    label="Most Likely Disease Suspected"
                    data={report.diseaseSuspected}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <DataDisplay
                    label="Confirmatory Diagnosis"
                    data={report.confirmatoryDiagnosis}
                  />
                </div>
                <div className="col">
                  <DataDisplay label="Vet's Comments" data={report.comments} />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
}
