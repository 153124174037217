import React, { Component } from "react";
import CenterBlock from "./CenterBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../Utils/Constants";
import APICall from "../Utils/APICall";
import Spinner from "../Utils/Spinner";
import MyTable from "../Utils/MyTable";
import Modal from "react-modal";
import Functions from "../Utils/Functions";
import { throttle } from "lodash";
import "moment/locale/en-gb.js";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import DateFnsUtils from "@date-io/date-fns";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import "../sass/file-preview.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import DataAPI from "../Utils/DataAPI";
import { isThisHour } from "date-fns";
import { DataContext } from "../Contexts/DataContexts";

export default class CertificatesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      errorMessage: null,
      searchQuery: "",
      page: 1,
      pageSize: 10,
      modalIsOpen: false,
      uploadErrorMessage: null,
      uploading: false,
      redirect: null,
      user: {},
      alreadyGotResults: false,
      fromDate: this.defaultStartDate(),
      toDate: new Date(),
      pdfDocument: null,
      selectedCertificate: null,
      id: ""
    };

    this.RenderTable = this.RenderTable.bind(this);
    this.RenderSupplyLogTable = this.RenderSupplyLogTable.bind(this);
    this.init = this.init.bind(this);

    this.throttledReload = throttle(() => {
      this.init(this.state.page, this.state.pageSize, this.state.searchQuery);
    }, 500);
  }

  static contextType = DataContext;

  componentDidMount() {
    const { globalState, addItem } = this.context;
    this.setState({ profile: globalState?.data })
    const { id } = globalState?.user
    this.setState({ id })
  }

  defaultStartDate() {
    var today = new Date();
    var day = today.getDay();
    let diff = today.getDate() - day + (day == 0 ? -6 : 1);
    var oneWeekAgo = new Date(today.setDate(diff));
    return oneWeekAgo;
  }

  showDocument(certificateCode) {
    const certificateUrl = `${Constants.apiBaseUrl}/api/certificates/download/${certificateCode}/${this.state.id}`;
    const pdfRenderer = (
      <div
        className="row"
        style={{ marginLeft: "-50px", marginRight: "-50px" }}
      >
        <div className="col">
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri: certificateUrl }]}
          />
        </div>
      </div>
    );

    this.setState({ pdfDocument: pdfRenderer }, () => { });
  }

  componentDidMount() {
    this.init();
    Modal.setAppElement("body");
  }

  getSupplyHistory = async (certificate) => {
    this.setState({ selectedCertificate: certificate });
    let supplyLog = await DataAPI.listSupplyLog(certificate.code);
    if (supplyLog) {
      supplyLog = supplyLog.filter((a) => a.accepted);
      this.setState({ supplyLog: supplyLog });
    }
  };

  RenderTable(_data) {
    if (!_data) return null;
    console.log(_data);

    const headerList = [
      {
        Header: "Certificate Code",
        accessor: "codeComponent",
      },
      {
        Header: "Supplier",
        accessor: "supplierComponent",
      },
      {
        Header: "Supply Date",
        accessor: "dateComponent",
      },
      {
        Header: "Net Weight",
        accessor: "beefWeightInKg",
      },
      {
        Header: "",
        accessor: "supplyLogComponent",
      },
    ];

    const tableHeader = React.useMemo(() => headerList, []);

    _data._data.forEach((element) => {
      element.dateComponent = Functions.toReadableDate(element.timeIssued);
      //  element.amountComponent = Functions.currencyFormat(element.amountPaid);
      element.supplierComponent = element.distributor.companyName;
      element.codeComponent = (
        <span
          onClick={() => this.showDocument(element.code)}
          style={{ fontFamily: "Helvetica, Arial", cursor: "pointer" }}
        >
          {element.code}
        </span>
      );
      element.supplyLogComponent = (
        <button
          type="button"
          className="btn btn-sm btn-link"
          onClick={() => this.getSupplyHistory(element)}
          style={{ fontFamily: "Helvetica, Arial", cursor: "pointer" }}
        >
          Supply Log
        </button>
      );
    });

    const tableData = React.useMemo(() => _data, []);
    return (
      <div style={{ zoom: "0.85" }}>
        <MyTable
          columns={tableHeader}
          cssArray={[
            "font-weight-bold",
            "",
            "text-right",
            "text-center",
            "text-right",
          ]}
          data={tableData._data}
          reloadData={(page, pageSize, query) =>
            this.init(page, pageSize, query)
          }
          enableSearch={true}
        />
      </div>
    );
  }

  RenderSupplyLogTable(_data) {
    if (!_data) return null;
    console.log("_dataaaa", _data);

    const headerList = [
      {
        Header: "Retail Outlet",
        accessor: "outletComponent",
      },
      {
        Header: "Supply Code",
        accessor: "supplyCode",
      },
      {
        Header: "Date Issued",
        accessor: "dateComponent",
      },
      {
        Header: "Weight Supplied (kg)",
        accessor: "weight",
      },
    ];

    const tableHeader = headerList;

    _data.forEach((element) => {
      element.dateComponent = Functions.toReadableDate(element.dateSupplied);
      element.outletComponent = element.retailer.name;
    });

    const tableData = _data;
    return (
      <div style={{ zoom: "0.85" }}>
        <MyTable
          columns={tableHeader}
          cssArray={["font-weight-bold", "", "text-right", "text-right"]}
          data={tableData}
        />
      </div>
    );
  }

  quickReload() {
    this.init(this.state.page, this.state.pageSize, this.state.searchQuery);
  }

  init(page, pageSize, query) {
    if (!query) query = "";

    this.setState({
      errorMessage: null,
      loading: true,
      searchQuery: query,
      page: page,
      pageSize: pageSize,
    });

    const issuedBy = this.props.issuedBy ? this.props.issuedBy : 0;
    const issuedTo = this.props.issuedTo ? this.props.issuedTo : 0;
    const dateFrom = Functions.sqlDateString(this.state.fromDate);
    const dateTo = Functions.sqlDateString(this.state.toDate);
    if (!page) page = 1;
    if (!pageSize) pageSize = 10;

    APICall(
      `api/certificates/list/${issuedBy}/${issuedTo}/${dateFrom}/${dateTo}/${page}/${pageSize}?search=${query}`,
      "GET",
      {}
    )
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ loading: false, data: null }, () => {
            this.setState({ data: response.data });
          });
        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Try again after sometime",
            loading: false,
          });
        }
        console.log(data);
      })
      .catch((error) => {
        this.setState({
          errorMessage:
            "Your request generated an error. Please check your network connection",
          loading: false,
        });
      });
  }

  render() {
    return this.state.redirect ? (
      <Redirect to={this.state.redirect} />
    ) : this.state.errorMessage ? (
      <CenterBlock height="150">
        <h1 className="text-danger icon-lg">
          <i className="mdi mdi-alert-circle"></i>
        </h1>
        <div className="text-dark text-center" role="alert">
          An error occurred, you can try reloading the page
        </div>
        <button
          type="button"
          onClick={() => this.init()}
          className="btn btn-inverse-success btn-fw mt-4 mb-5 btn-rounded"
        >
          Reload
        </button>
      </CenterBlock>
    ) : (
      <>
        {this.state.loading ? (
          <div className="text-success" style={{ position: "absolute" }}>
            <Spinner size="1.5" />{" "}
          </div>
        ) : null}

        {this.state.supplyLog && (
          <Modal
            isOpen={this.state.supplyLog ? true : false}
            contentLabel=""
            style={Constants.defaultModalStyle}
            onRequestClose={() => {
              this.setState({ supplyLog: null });
            }}
          >
            {this.state.supplyLog.length <= 0 ? (
              <>
                <p className="text-danger mt-4 mb-4 text-center">
                  No supply logs available
                </p>
              </>
            ) : (
              <>
                <p className="mb-0">Certificate number:</p>
                <h5 className="text-dark">
                  {this.state.supplyLog[0].certificateCode}
                </h5>

                <p className="mb-0">Supplier:</p>
                <h5 className="text-dark">
                  {this.state.supplyLog[0].supplierCompany.companyName}
                </h5>
                <hr />
                {this.RenderSupplyLogTable(this.state.supplyLog)}

                <div className="row mt-5">
                  <div className="col">
                    <p className="mb-0">Consignment total:</p>
                    <h5 className="text-dark">
                      {this.state.selectedCertificate.beefWeightInKg}
                      kg
                    </h5>
                  </div>
                  <div className="col text-right">
                    <p className="mb-0">Total supplied:</p>
                    <h5 className="text-dark">
                      {this.state.supplyLog.reduce((sum, current) => {
                        return sum + current.weight;
                      }, 0)}
                      kg
                    </h5>
                  </div>
                </div>
              </>
            )}
          </Modal>
        )}

        <Modal
          isOpen={this.state.pdfDocument ? true : false}
          contentLabel=""
          style={Constants.transparentModalStyle}
          onRequestClose={() => {
            this.setState({ pdfDocument: null });
          }}
        >
          <PerfectScrollbar>
            <div
              className="mymodal-wrapper file-preview-container lightbox-style"
              style={{
                width: "100vw",
                maxWidth: "700px",
                maxHeight: "90vh",
              }}
            >
              {this.state.pdfDocument}
            </div>
          </PerfectScrollbar>
        </Modal>

        <div style={{ zoom: "0.85" }} className="mb-3">
          <div className="form-group row">
            <div className="col col-md-3">
              <label className="control-label font-weight-bold text-darker">
                Date from
              </label>
              <DatePickerInput
                defaultValue={this.state.fromDate}
                onChange={(date) =>
                  this.setState({ fromDate: date }, () => {
                    this.quickReload();
                  })
                }
                className="my-custom-datepicker-component"
              />
            </div>
            <div className="col text-center" style={{ maxWidth: "20px" }}>
              <label
                style={{ visibility: "hidden" }}
                className="control-label font-weight-bold text-darker"
              >
                -
              </label>
              <label className="control-label font-weight-bold text-darker mt-2">
                _
              </label>
            </div>
            <div className="col col-md-3">
              <label className="control-label font-weight-bold text-darker">
                Date to
              </label>
              <DatePickerInput
                defaultValue={this.state.toDate}
                onChange={(date) =>
                  this.setState({ toDate: date }, () => {
                    this.quickReload();
                  })
                }
                className="my-custom-datepicker-component"
              />
            </div>
          </div>
        </div>
        <br style={{ clear: "both" }} />

        {!this.state.data ? null : <this.RenderTable _data={this.state.data} />}
      </>
    );
  }
}
