import React, { Component, useEffect, useState } from "react";
import Constants from "../../Utils/Constants";

export default function AddWebScripts() {
  const [loading, setLoading] = useState(true);

  const jsScripts = [
    "/landing-page/js/jquery.js",
    "/landing-page/js/popper.min.js",
    "/landing-page/js/bootstrap.min.js",
    "/landing-page/js/bootstrap-select.min.js",
    "/landing-page/js/jquery.fancybox.js",
    "/landing-page/js/isotope.js",
    "/landing-page/js/owl.js",
    "/landing-page/js/appear.js",
    "/landing-page/js/wow.js",
    "/landing-page/js/lazyload.js",
    "/landing-page/js/scrollbar.js",
    "/landing-page/js/TweenMax.min.js",
    "/landing-page/js/swiper.min.js",
    "/landing-page/js/script.js",
  ];

  const stylesheets = [
    "/landing-page/css/bootstrap.css",
    "/landing-page/css/style.css",
    "/landing-page/css/responsive.css",
    "/landing-page/css/color.css",
  ];
  const loadedScripts = [];
  const loadedStyles = [];

  const loadScripts = function () {
    loadStyles();
    jsScripts.forEach((scriptUrl) => {
      const script = document.createElement("script");
      script.src = Constants.subfolder + scriptUrl;
      script.async = true;
      document.body.appendChild(script);
      loadedScripts.push(script);
    });
  };

  ///loadStyles is called in loadScripts too
  const loadStyles = function () {
    stylesheets.forEach((css) => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = Constants.subfolder + css;
      document.head.appendChild(link);
      loadedStyles.push(link);
    });
  };

  const unloadScripts = function () {
    loadedScripts.forEach((script) => {
      document.body.removeChild(script);
    });
    loadedStyles.forEach((css) => {
      document.head.removeChild(css);
    });
  };

  useEffect(() => {
    document.body.classList.add("notvisible");
    loadScripts();

    setTimeout(() => {
      document.body.classList.remove("notvisible");
    }, 2000);

    return () => unloadScripts();
  }, []);

  return null;
}
