import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import Modal from "react-modal";
import Select from "react-select";
import DataAPI from "../../Utils/DataAPI";
import Functions from "../../Utils/Functions";
import DiseaseList from "../../Components/DiseaseReporting/DiseaseList";
import NewDiseaseReport from "../../Components/DiseaseReporting/NewDiseaseReport";

export default class IssueCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diseaseListLoading: false,
      loading: false,
      data: null,
      errorMessage: null,
      step: 1,
      currentCheckin: {},
      distributors: [],
      redirectUrl: null,
      payload: {},
      investors: [],
      validateLoading: false,
      animal: null,
      modalIsOpen: false,
      destinations: [],
    };

    this.formControl = this.formControl.bind(this);
    this.resetDiseaseList = this.resetDiseaseList.bind(this);
  }

  componentDidMount() {
    this.getLastCheckin();
    Modal.setAppElement("body");
  }

  async validateTagNumber() {
    this.setState({ validateLoading: true, animal: null });
    let animal = await DataAPI.findAnimal(this.state.payload?.tagNumber);
    if (animal) {
      this.setState({ animal: animal });
      this.updateProp(animal.specie, "animalSpecie");
      this.updateProp(
        this.state.payload.tagNumber.toUpperCase(),
        "identificationMark"
      );
    } else {
      Toastr("error", "Invalid animal tag number");
    }
    this.setState({ validateLoading: false });
  }

  addDestination = () => {
    let destinations = this.state.destinations;
    if (!destinations) destinations = [];

    if (this.state.newDestination && this.state.newDestination.trim()) {
      if (
        destinations.find(
          (a) =>
            a.toLowerCase() == this.state.newDestination.trim().toLowerCase()
        )
      ) {
        return;
      }
      destinations.push(this.state.newDestination.trim());
      this.setState({ destinations: destinations, newDestination: "" });
    }
  };

  removeDestination = (destination) => {
    let _destinations = this.state.destinations.filter((a) => a != destination);
    this.setState({ destinations: _destinations });
  };

  advanceStep1 = async () => {
    if (
      !this.state.payload?.animalSpecie ||
      !this.state.payload?.beefWeightInKg
    ) {
      Toastr("error", "Some required fields are not filled");
      return;
    }

    if (await this.checkWeight()) {
      this.setState({ step: 2 });
    }
  };

  getLastCheckin() {
    APICall("api/vets/lastcheckin", "get")
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          if (response.data) {
            this.setState({ currentCheckin: response.data }, () => {
              //investors are tied to abattoirs
              this.getInvestors();
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkWeight = async () => {
    if (!this.state.animal?.tagNumber || !this.state.payload?.beefWeightInKg) {
      Toastr("error", "Check that form is properly filled");
      return false;
    }

    let weightOk = false;
    await APICall(
      `api/certificates/checkweight/${this.state.animal.tagNumber}/${this.state.payload.beefWeightInKg}`,
      "post"
    )
      .then((data) => {
        if (data.data.isSuccess) {
          weightOk = true;
        } else {
          Toastr("error", data.data.message);
        }
      })
      .catch((error) => {
        Toastr("error", "Unable to continue, check your network");
      });

    return weightOk;
  };

  getInvestors() {
    APICall(
      `api/abattoirs/listInvestors/${this.state.currentCheckin.id}`,
      "get"
    )
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          if (response.data) {
            response.data.forEach((element) => {
              element.value = element.id;
              element.label = element.investorName;
            });
            this.setState({ investors: response.data });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  issueCertificate() {
    if (!this.state.destinations || this.state.destinations.length <= 0) {
      this.setState({
        loading: false,
        errorMessage: "Add at least one destination",
      });
      return;
    }
    let payload = this.state.payload;
    payload.destinations = this.state.destinations;
    payload.abattoirId = this.state.currentCheckin.id;
    payload.numberOfParts = parseInt(payload.numberOfParts) || 1;
    payload.beefWeightInKg = parseFloat(payload.beefWeightInKg);

    if (payload.paymentChannel?.toLowerCase() == "card") {
      payload.receiptNumber = "";
    }

    this.setState({ loading: true, errorMessage: null });
    APICall("api/certificates/issue", "post", payload)
      .then((data) => {
        if (data.data.isSuccess) {
          Toastr("success", "Certificate issued successfully");
          this.setState({
            redirectUrl: "/in/new-certificate-success/" + data.data.data.code,
          });
        } else {
          this.setState({ loading: false, errorMessage: data.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          errorMessage: "Check your network connection",
        });
      });
  }

  modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "visible",
    },
  };

  updateProp(value, prop) {
    let payload = this.state.payload;
    if (!payload) payload = {};
    payload[prop] = value;
    this.setState({ payload: payload });
  }

  handleChange(option) {
    let payload = this.state.payload;
    if (!payload) payload = {};
    payload.distributorId = option.id;
    this.setState({ payload: payload });
  }

  formGroupRow({ colClass, children, extraChildren }) {
    return (
      <div className="form-group row">
        <div className={colClass}>{children}</div>
        {extraChildren && <div className={colClass}>{extraChildren}</div>}
      </div>
    );
  }

  formControl({ label, prop, inputType, required }) {
    if (!inputType) inputType = "text";
    let payload = this.state.payload;
    if (!payload) payload = {};

    return (
      <>
        <label className="small control-label font-weight-bold text-darker">
          {label} {required && <small className="text-danger">*</small>}
        </label>
        <input
          type={inputType}
          className="form-control form-control-sm"
          placeholder=""
          defaultValue={payload[prop]}
          onChange={(e) => this.updateProp(e.target.value, prop)}
        />
      </>
    );
  }

  resetDiseaseList() {
    this.setState({ modalIsOpen: false, diseaseListLoading: true }, () => {
      setTimeout(() => {
        this.setState({ diseaseListLoading: false });
      }, 1000);
    });
  }

  singleAnimalItem = ({ label, prop }) => {
    return (
      <>
        <p className="small">
          <strong className="text-muted">{label}</strong>
          <br />
          {this.state.animal[prop]}
          <hr />
        </p>
      </>
    );
  };

  animalDisplay = () => {
    return (
      <div className="row text-dark">
        <div className="col-6">
          {this.singleAnimalItem({ label: "Specie", prop: "specie" })}
        </div>
        <div className="col-6 text-right">
          {this.singleAnimalItem({ label: "Sub-specie", prop: "subSpecie" })}
        </div>
        <div className="col-6">
          {this.singleAnimalItem({ label: "Colour", prop: "color" })}
        </div>
        <div className="col-6 text-right">
          {this.singleAnimalItem({ label: "Weight (kg)", prop: "weight" })}
        </div>
        <div className="col-6">
          {this.singleAnimalItem({
            label: "Animal Owner",
            prop: "animalOwner",
          })}
        </div>
        <div className="col-6 text-right">
          {this.singleAnimalItem({
            label: "State of origin",
            prop: "stateOfOrigin",
          })}
        </div>
        <div className="col-12">
          {this.singleAnimalItem({
            label: "Vet's Observations",
            prop: "vetObservations",
          })}
        </div>
      </div>
    );
  };

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <MainLayout title="Issue Certificate">
        <Modal
          isOpen={this.state.modalIsOpen}
          contentLabel=""
          style={Constants.defaultModalStyle}
          onRequestClose={() => this.resetDiseaseList()}
        >
          <div
            className="mymodal-wrapper"
            style={{ maxHeight: "450px", overflowY: "scroll" }}
          >
            <NewDiseaseReport
              returnAction={() => this.resetDiseaseList()}
              tagNumber={this.props.tagNumber || this.state.payload?.tagNumber}
            />
          </div>
        </Modal>
        <div className="row">
          <div className="col-md-12 mb-5">
            {this.state.step == 1 && (
              <CardBlock title="">
                <div className="row">
                  <div className="col-md-12 mb-0">
                    <form className="forms-sample">
                      <div className="form-group row">
                        <div className="col col-md-6 mx-auto mt-3">
                          <>
                            <label className="control-label font-weight-bold text-darker">
                              Animal Tag Number
                            </label>
                            <div className="input-group input-group-sm mb-3">
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.updateProp(e.target.value, "tagNumber")
                                }
                                className="form-control"
                              />
                              <div className="input-group-append">
                                {this.state.validateLoading ? (
                                  <Spinner />
                                ) : (
                                  <button
                                    onClick={() => this.validateTagNumber()}
                                    className="btn btn-success"
                                    type="button"
                                  >
                                    Fetch Animal
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                      {this.state.animal && (
                        <>
                          <div className="form-group row">
                            <div className="col-12 col-md-8 col-lg-8 mx-auto">
                              <h5 className="theme-color mb-4 mt-4">
                                Animal Delivery Information
                              </h5>
                              {this.animalDisplay()}
                            </div>
                          </div>
                          <hr style={{ visibility: "hidden" }} />
                          <div className="form-group row">
                            <div className="col-12 col-md-8 col-lg-8 mx-auto mt-2">
                              <h4 className="theme-color">
                                Details for identification of meat
                              </h4>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-8 col-lg-8 mx-auto">
                              <div className="form-group row">
                                <div className="col-12 col-md-4">
                                  <this.formControl
                                    label="Type of parts"
                                    prop="typeOfParts"
                                  />
                                </div>
                                <div className="col-12 col-md-4">
                                  <this.formControl
                                    label="Type of packaging"
                                    prop="typeOfPackaging"
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-12 col-md-4">
                                  <this.formControl
                                    label="Number of parts"
                                    prop="numberOfParts"
                                    inputType="number"
                                  />
                                </div>
                                <div className="col-12 col-md-4">
                                  <this.formControl
                                    label="Net Weight (in kg)"
                                    prop="beefWeightInKg"
                                    inputType="number"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.errorMessage ? (
                            <div className="mb-3">
                              <div
                                className="alert alert-danger small"
                                role="alert"
                              >
                                {this.state.errorMessage}
                              </div>
                            </div>
                          ) : null}

                          <div className="form-group row">
                            <div className="col col-md-2 mt-4"></div>
                            <div className="col col-md-3 mt-4">
                              <SpinnerButton
                                onClick={() => this.advanceStep1()}
                                className="btn btn-success mr-2 btn-block btn-rounded"
                                label="Continue"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </CardBlock>
            )}
            {this.state.step == 2 && (
              <CardBlock title="">
                <div className="row">
                  <div className="col-md-12 mb-0">
                    <form className="forms-sample">
                      <div className="form-group row">
                        <div className="col col-md-6">
                          <h4 className="theme-color">Disease Reporting</h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-4">
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({ modalIsOpen: true })
                              }
                              className="btn btn-danger btn-sm mb-4 float-right btn-rounded"
                            >
                              + New Disease Report
                            </button>
                            <hr style={{ clear: "both" }} />
                          </div>

                          {this.state.diseaseListLoading ? (
                            <Spinner />
                          ) : (
                            <DiseaseList
                              tagNumber={
                                this.props.tagNumber ||
                                this.state.payload?.tagNumber
                              }
                            />
                          )}
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col mt-4">
                          <div className="row">
                            <div className="col">
                              <button
                                type="button"
                                onClick={() => this.setState({ step: 1 })}
                                className="btn btn-link"
                              >
                                {"< "} Back
                              </button>
                            </div>
                            <div className="col text-right">
                              <button
                                type="button"
                                onClick={() => this.setState({ step: 3 })}
                                className="btn btn-link"
                              >
                                Next {" >"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </CardBlock>
            )}
            {this.state.step == 3 && (
              <CardBlock title="">
                <div className="row">
                  <div className="col-md-12 mb-0">
                    <form className="forms-sample">
                      <div className="form-group row">
                        <div className="col col-md-6">
                          <h4 className="theme-color">Details of Journey</h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6 mx-auto">
                          <div className="form-group row">
                            <div className="col">
                              <label className="control-label font-weight-bold text-darker">
                                Approved slaughter house
                              </label>
                              <input
                                type="text"
                                readOnly
                                className="form-control form-control-sm"
                                placeholder={this.state.currentCheckin.name}
                                defaultValue={this.state.currentCheckin.name}
                              />
                            </div>
                          </div>

                          <this.formGroupRow
                            colClass="col"
                            children={
                              <>
                                <label className="control-label font-weight-bold text-darker">
                                  Veterinarian's Comments
                                </label>
                                <textarea
                                  defaultValue={this.state.payload.comments}
                                  className="form-control form-control-sm"
                                  placeholder="Optional comments by vet"
                                  onChange={(e) =>
                                    this.updateProp(e.target.value, "comments")
                                  }
                                />
                              </>
                            }
                          />

                          <div className="form-group row">
                            <div className="col-12">
                              <label className="control-label font-weight-bold text-darker">
                                Meat is dispatched to
                              </label>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  value={this.state.newDestination}
                                  className="form-control form-control-sm"
                                  placeholder="Place and state of destination"
                                  onChange={(e) =>
                                    this.setState({
                                      newDestination: e.target.value,
                                    })
                                  }
                                />
                                <div className="input-group-append">
                                  <button
                                    onClick={() => this.addDestination()}
                                    className="btn btn-dark"
                                    type="button"
                                  >
                                    + Add destination
                                  </button>
                                </div>
                              </div>
                            </div>
                            {this.state.destinations &&
                              this.state.destinations.length > 0 && (
                                <div className="col-12 mt-3">
                                  {this.state.destinations.map(
                                    (destination, index) => (
                                      <p
                                        key={index}
                                        className="text-dark border-bottom pb-1 mb-1"
                                      >
                                        <i
                                          onClick={() =>
                                            this.removeDestination(destination)
                                          }
                                          className="text-danger clickable mdi mdi-close mr-2"
                                        ></i>{" "}
                                        {destination}
                                      </p>
                                    )
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {this.state.errorMessage ? (
                        <div className="mb-3">
                          <div
                            className="alert alert-danger small"
                            role="alert"
                          >
                            {this.state.errorMessage}
                          </div>
                        </div>
                      ) : null}

                      <div className="form-group row">
                        <div className="col mt-4">
                          <div className="row">
                            <div className="col">
                              <button
                                onClick={() => this.setState({ step: 1 })}
                                className="btn btn-link"
                              >
                                {"< "} Back
                              </button>
                            </div>
                            <div className="col text-right">
                              <SpinnerButton
                                onClick={() => this.issueCertificate()}
                                className="btn btn-success btn-rounded"
                                label="Issue Certificate"
                                loading={this.state.loading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </CardBlock>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }
}
