import React, { Component } from "react";
import CardBlock from "./CardBlock";
import Tabs from "../Utils/Tabs";
import CenterBlock from "./CenterBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../Utils/Constants";
import APICall from "../Utils/APICall";
import Spinner from "../Utils/Spinner";
import SpinnerButton from "../Utils/SpinnerButton";
import Toastr from "../Utils/Toastr";
import MyTable from "../Utils/MyTable";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import Functions from "../Utils/Functions";
import Select from "react-select";
import { throttle } from "lodash";

export default class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      errorMessage: null,
      searchQuery: "",
      page: 1,
      pageSize: 10,
      modalIsOpen: false,
      uploadErrorMessage: null,
      uploading: false,
      redirect: null,
      user: {},
      alreadyGotResults: false,
      userType: this.props.userType || "all",
      currentUserType: this.props.userType,
    };

    this.RenderTable = this.RenderTable.bind(this);
    this.setLoggedInUser = this.setLoggedInUser.bind(this);
    this.init = this.init.bind(this);

    this.throttledReload = throttle(() => {
      this.init(this.state.page, this.state.pageSize, this.state.searchQuery);
    }, 500);
  }

  userTypes = [
    { label: "Patients", value: "Patient" },
    { label: "Doctors", value: "Doctor" },
    { label: "Hospital Administrators", value: "HospitalAdmin" },
    { label: "Staff Admins", value: "Admin" },
    { label: "Super Admins", value: "SuperAdmin" },
  ];

  filterUserType(option) {
    this.setState({ userType: option.value }, () => {
      this.init(this.state.page, this.state.pageSize, this.state.searchQuery);
    });
  }

  componentDidMount() {
    Modal.setAppElement("body");
    new Functions().whoIsLoggedIn(this.setLoggedInUser, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentUserType != this.props.userType) {
      this.setState({ currentUserType: this.props.userType });
      this.throttledReload();
    }
  }

  setLoggedInUser(user, error) {
    this.setState({ loading: true });

    if (!error) {
      this.setState({ user: user }, () => {
        this.init(1, this.state.pageSize, "");
      });
    } else {
      this.setState({
        errorMessage:
          "Your request generated an error. Try again after sometime",
        loading: false,
      });
    }
  }

  RenderTable(_data) {
    var headerList = [
      {
        Header: "Name",
        accessor: "nameComponent",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Phone number",
        accessor: "phoneNumber",
      },
    ];

    if (!this.props.userType) {
      headerList.push({
        Header: "User Type",
        accessor: "roleComponent",
      });
    }

    const tableHeader = React.useMemo(() => headerList, []);

    _data._data.forEach((element) => {
      var roleColor =
        element.effectiveRole == "SuperAdmin"
          ? "danger"
          : element.effectiveRole == "Admin"
            ? "warning"
            : element.effectiveRole == "HospitalAdmin"
              ? "info"
              : element.effectiveRole == "Doctor"
                ? "success"
                : element.effectiveRole == "Patient"
                  ? "primary"
                  : "light";

      element.nameComponent = (
        <Link
          className="font-weight-bold text-dark"
          style={{ textDecoration: "none" }}
          to={"/in/profile/" + element.userName.toString()}
        >
          {element.name}
        </Link>
      );
      element.roleComponent = (
        <div
          style={{ zoom: "0.9", textTransform: "uppercase" }}
          className={`badge badge-${roleColor}`}
        >
          {element.effectiveRole}
        </div>
      );
    });

    const tableData = React.useMemo(() => _data, []);
    return (
      <div style={{ zoom: "0.85" }}>
        <MyTable
          columns={tableHeader}
          data={tableData._data}
          reloadData={this.init}
          enableSearch={true}
          searchQuery={this.state.searchQuery}
          page={this.state.page}
          pageSize={this.state.pageSize}
          cssArray={["", "", "", "text-right"]}
        />
      </div>
    );
  }


  init(page, pageSize, query) {
    if (!query) query = "";

    this.setState({
      errorMessage: null,
      loading: true,
      searchQuery: query,
      page: page,
      pageSize: pageSize,
    });

    APICall(
      `api/users/Fetch/${pageSize}/${page}/${this.state.userType}?query=${query}`,
      "GET",
      {}
    )
      .then((data) => {
        var response = data.data;
        if (response.status == "success") {
          this.setState({ loading: false, data: null }, () => {
            this.setState({ data: response.data });
          });
        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Try again after sometime",
            loading: false,
          });
        }
        console.log(data);
      })
      .catch((error) => {
        this.setState({
          errorMessage:
            "Your request generated an error. Please check your network connection",
          loading: false,
        });
      });
  }

  render() {
    return this.state.redirect ? (
      <Redirect to={this.state.redirect} />
    ) : this.state.errorMessage ? (
      <CenterBlock height="150">
        <h1 className="text-danger icon-lg">
          <i className="mdi mdi-alert-circle"></i>
        </h1>
        <div className="text-dark text-center" role="alert">
          An error occurred, you can try reloading the page
        </div>
        <button
          type="button"
          onClick={() => this.init()}
          className="btn btn-inverse-primary btn-fw mt-4 mb-5"
        >
          Reload
        </button>
      </CenterBlock>
    ) : (
      <>
        {this.state.loading ? (
          <div className="text-success" style={{ position: "absolute" }}>
            <Spinner size="1.5" />{" "}
          </div>
        ) : null}
        {this.props.userType ? null : (
          <div
            style={{
              maxWidth: "350px",
              float: "right",
              width: "350px",
              zoom: "0.85",
            }}
          >
            <div className="row">
              <div className="col text-right pt-2" style={{ maxWidth: "90px" }}>
                Role filter
              </div>
              <div className="col">
                <Select
                  styles={Constants.selectStyles}
                  placeholder="filter by role"
                  options={this.userTypes}
                  onChange={(option) => this.filterUserType(option)}
                />
              </div>
            </div>
          </div>
        )}

        {!this.state.data ? null : <this.RenderTable _data={this.state.data} />}
      </>
    );
  }
}
