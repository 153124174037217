import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import CardBlock from "../../Components/CardBlock";
import { Link, Redirect } from "react-router-dom";
import VerifyCertificate from "../../Components/VerifyCertificate";

export default class VerifyCertificateAfterLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <MainLayout title="Verify Certificate">
        <div className="row">
          <div className="col-12 col-md-6 mb-5 mx-auto">
            <CardBlock title="">
              <VerifyCertificate returnUrl="/in/verify-certificate" />
            </CardBlock>
          </div>
        </div>
      </MainLayout>
    );
  }
}