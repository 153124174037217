import React from "react";

export default class Menus {
  static operationsassistant = [
    { label: "Home", icon: "mdi mdi-home", url: "/home" },
    {
      label: "Take Delivery",
      icon: "mdi mdi-border-color",
      url: "/take-delivery",
    },
    {
      label: "Take Tag Delivery",
      icon: "mdi mdi-border-color",
      url: "/take-tag-delivery",
    },
  ];

  static vet = [
    { label: "Home", icon: "mdi mdi-home", url: "/home" },
    {
      label: "Take Delivery",
      icon: "mdi mdi-border-color",
      url: "/take-delivery",
    },
    {
      label: "Take Tag Delivery",
      icon: "mdi mdi-border-color",
      url: "/take-tag-delivery",
    },
    {
      label: "New Certificate",
      icon: "mdi mdi-book-plus",
      url: "/new-certificate",
    },
    {
      label: "Issued Certificates",
      icon: "mdi mdi-book-multiple-variant",
      url: "/issued-certificates",
    },
    {
      label: "Disease Reports",
      icon: "mdi mdi-book-open",
      url: "/my-disease-reports",
    },
    {
      label: "Send Sample to Lab",
      icon: "mdi mdi-flask-outline",
      url: "/send-test-to-lab",
    },
    {
      label: "My Samples",
      icon: "mdi mdi-clipboard-text",
      url: "/vets-test-samples-view",
    },
    {
      label: "Verify Certificate",
      icon: "mdi mdi-file-check",
      url: "/verify-certificate",
    },
  ];

  static vetsupervisor = [
    { label: "Home", icon: "mdi mdi-home", url: "/home" },
    {
      label: "Certificates",
      icon: "mdi mdi-book-multiple-variant",
      url: "/all-certificates",
    },
    {
      label: "Vets Records",
      icon: "mdi mdi-hospital",
      url: "/vets-management",
    },
    {
      label: "Abattoirs",
      icon: "mdi mdi-houzz",
      url: "/abattoirs-view",
    },
    {
      label: "Verify Certificate",
      icon: "mdi mdi-file-check",
      url: "/verify-certificate",
    },
  ];

  static admin = [
    { label: "Home", icon: "mdi mdi-home", url: "/home" },
    {
      label: "Abattoirs",
      icon: "mdi mdi-houzz",
      url: "/abattoirs",
    },
    {
      label: "Vets Management",
      icon: "mdi mdi-hospital",
      url: "/vets-management",
    },
    {
      label: "Ranch",
      icon: "mdi mdi-settings",
      url: "/ranch",
    },
    {
      label: "Field Inspectors",
      icon: "mdi mdi-hospital",
      url: "/inspectors",
    },
    {
      label: "Laboratories",
      icon: "mdi mdi-flask",
      url: "/labs-management",
    },
    {
      label: "Suppliers",
      icon: "mdi mdi-car-connected",
      url: "/distributors",
    },
    {
      label: "Certificates",
      icon: "mdi mdi-book-multiple-variant",
      url: "/all-certificates",
    },
    {
      label: "Inspection Reports",
      icon: "mdi mdi-book-multiple-variant",
      url: "/all-inspection-reports/0/0/0",
    },
    {
      label: "Settings",
      icon: "mdi mdi-settings",
      url: "/settings",
    },
    {
      label: "Specie Configuration",
      icon: "mdi mdi-cow",
      url: "/specie-configuration",
    },
    {
      label: "Verify Certificate",
      icon: "mdi mdi-file-check",
      url: "/verify-certificate",
    },
  ];

  static superadmin = [
    ...this.admin,
    {
      label: "Revenue Records",
      icon: "mdi mdi-settings",
      url: "/revenue",
    },
    {
      label: "Bank Accounts",
      icon: "mdi mdi-settings",
      url: "/bank",
    },
    {
      label: "Users Reports",
      icon: "mdi-paperclip",
      url: "/users-reports",
    },

  ];


  static ranch = [
    {
      label: "Home",
      icon: "mdi mdi-home",
      url: "/home"
    },
    {
      label: "Cattle",
      icon: "mdi mdi-home",
      url: "/ranch/Cattle"
    },
    {
      label: "Pig",
      icon: "mdi mdi-file-check",
      url: "/ranch/Pig",
    },

    {
      label: "Sheep and Goat",
      icon: "mdi mdi-file-check",
      url: "/ranch/Sheep and Goat",
    },
    {
      label: "Mollusc",
      icon: "mdi mdi-file-check",
      url: "/ranch/Molluscs",
    },
    {
      label: "Camel",
      icon: "mdi mdi-file-check",
      url: "/ranch/Camel",
    },
    {
      label: "Fish",
      icon: "mdi mdi-file-check",
      url: "/ranch/Fish",
    },
    {
      label: "Crustacean",
      icon: "mdi mdi-file-check",
      url: "/ranch/Crustaceans",
    }
  ];

  static distributor = [
    { label: "Home", icon: "mdi mdi-home", url: "/home" },
    {
      label: "Card Setup",
      icon: "mdi mdi-credit-card",
      url: "/card",
    },
    {
      label: "My Certificates",
      icon: "mdi mdi-book-multiple-variant",
      url: "/my-certificates",
    },
    {
      label: "New Delivery",
      icon: "mdi mdi-account-switch",
      url: "/supplier-delivery",
    },
    {
      label: "Verify Certificate",
      icon: "mdi mdi-file-check",
      url: "/verify-certificate",
    },
  ];

  static regular = [
    { label: "Home", icon: "mdi mdi-home", url: "/home" },
    {
      label: "Receive Consignment",
      icon: "mdi mdi-cube-send",
      url: "/receive-consignment",
    },
    {
      label: "Delivery Log",
      icon: "mdi mdi-format-align-center",
      url: "/my-delivery-log",
    },
    {
      label: "Verify Certificate",
      icon: "mdi mdi-file-check",
      url: "/verify-certificate",
    },
    {
      label: "Generate Tag",
      icon: "mdi mdi-file-check",
      url: "/generate-tag",
    }
  ];

  static laboratory = [
    { label: "Home", icon: "mdi mdi-home", url: "/home" },
    {
      label: "Samples List",
      icon: "mdi mdi-cube-send",
      url: "/lab-tests",
    },
    {
      label: "Verify Certificate",
      icon: "mdi mdi-file-check",
      url: "/verify-certificate",
    },
  ];

  static governmentinspector = [
    { label: "Home", icon: "mdi mdi-home", url: "/home" },
    {
      label: "Send Sample to Lab",
      icon: "mdi mdi-flask-outline",
      url: "/send-test-to-lab",
    },
    {
      label: "My Samples",
      icon: "mdi mdi-clipboard-text",
      url: "/vets-test-samples-view",
    },
    {
      label: "Verify Certificate",
      icon: "mdi mdi-file-check",
      url: "/verify-certificate",
    },
  ];
}
