import React, { Component, useEffect, useState } from "react";
import Constants from "../../Utils/Constants";

export default function AddWebScripts2() {
  const [loading, setLoading] = useState(true);

  const jsScripts = [
    "/landing-page2/js/jquery-3.3.1.min.js",
    "/landing-page2/js/bootstrap.min.js",
    "/landing-page2/js/slick.min.js",
    "/landing-page2/js/meanmenu.min.js",
    "/landing-page2/js/counterup.min.js",
    "/landing-page2/js/scrollup.min.js",
    "/landing-page2/js/waypoints.min.js",
    "/landing-page2/js/venobox.min.js",
    "/landing-page2/js/aos.min.js",
    "/landing-page2/js/easyzoom.min.js",
    "/landing-page2/js/range-slider.min.js",
    "/landing-page2/js/main.js",
  ];

  const stylesheets = [
    "/landing-page2/css/bootstrap.min.css",
    "/landing-page2/css/animate.min.css",
    "/landing-page2/css/icofont.min.css",
    "/landing-page2/css/slick.min.css",
    "/landing-page2/css/meanmenu.min.css",
    "/landing-page2/css/venobox.min.css",
    "/landing-page2/css/easyzoom.min.css",
    "/landing-page2/css/range-slider.min.css",
    "/landing-page2/css/cssanimation.min.css",
    "/landing-page2/css/default.css",
    "/landing-page2/css/style.css",
    "/landing-page2/css/responsive.css",
  ];
  const loadedScripts = [];
  const loadedStyles = [];

  const loadScripts = function () {
    loadStyles();
    jsScripts.forEach((scriptUrl) => {
      const script = document.createElement("script");
      script.src = Constants.subfolder + scriptUrl;
      script.async = true;
      document.body.appendChild(script);
      loadedScripts.push(script);
    });
  };

  ///loadStyles is called in loadScripts too
  const loadStyles = function () {
    stylesheets.forEach((css) => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = Constants.subfolder + css;
      document.head.appendChild(link);
      loadedStyles.push(link);
    });
  };

  const unloadScripts = function () {
    loadedScripts.forEach((script) => {
      document.body.removeChild(script);
    });
    loadedStyles.forEach((css) => {
      document.head.removeChild(css);
    });
  };

  useEffect(() => {
    document.body.classList.add("notvisible");
    loadScripts();

    setTimeout(() => {
      document.body.classList.remove("notvisible");
    }, 2000);

    return () => unloadScripts();
  }, []);

  return null;
}
