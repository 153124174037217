import React, { Component, useEffect, useState } from "react";
import { DataContext } from "./Contexts/DataContexts";
import "./App.css";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import dotenv from "dotenv";
import Routes from "./Routes";
import Constants from "./Utils/Constants";
import Functions from "./Utils/Functions";
import CenterBlock from "./Components/CenterBlock";
import Spinner from "./Utils/Spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

dotenv.config();

function App() {
  const [globalState, setGlobalState] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    new Functions().whoIsLoggedIn((u, e) => setLoggedInUser(u, e), true);
  }, []);

  const addItem = (key, value) => {
    let _globalState = globalState;
    _globalState[key] = value;
    setGlobalState(_globalState);
    console.log(`${key} added to global state: `, value);
  };

  const setLoggedInUser = (_user, error) => {
    addItem("user", _user);
    setLoading(false);
    console.log("loading in main app set to false");
  };

  return errorMessage ? (
    <CenterBlock>{errorMessage}</CenterBlock>
  ) : loading ? (
    <Spinner />
  ) : (
    <DataContext.Provider value={{ globalState, addItem }}>
      <ToastContainer />
      <BrowserRouter basename={Constants.subfolder}>
        <Switch>
          {Routes.map((route, index) => (
            <Route
              key={index}
              path={route.route}
              exact
              component={route.component}
            />
          ))}
        </Switch>
      </BrowserRouter>
    </DataContext.Provider>
  );
}

export default App;
