import React, { Component, useContext } from "react";
import TopNav from "../TopNav";
import Sidebar from "../Sidebar";
import Constants from "../../Utils/Constants";
import Functions from "../../Utils/Functions";
import { Link, Redirect } from "react-router-dom";
import CenterBlock from "../CenterBlock";
import Spinner from "../../Utils/Spinner";
import Tooltip from "react-tooltip-lite";
import { DataContext } from "../../Contexts/DataContexts";
import AddAppScripts from "./AddAppScripts";

export default class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      hideFloatingButton: false,
      hideActivationMessage: false,
      mobileMenuIsOpen: false,
    };

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  static contextType = DataContext;

  componentDidMount() {}

  toggleMobileMenu() {
    this.setState({ mobileMenuIsOpen: !this.state.mobileMenuIsOpen });
  }

  render() {
    return (
      <>
        <AddAppScripts />
        <TopNav toggleMobileMenu={this.toggleMobileMenu} />
        <div className="container-fluid page-body-wrapper">
          <Sidebar mobileMenuIsOpen={this.state.mobileMenuIsOpen} />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className={(this.props.title ? "" : "pt-0") + ""}>
                <div className="d-xl-flex justify-content-between align-items-start">
                  {this.props.title ? (
                    <h2 className="text-dark font-weight-bold mb-4 w-100">
                      {this.props.title}
                    </h2>
                  ) : null}

                  <nav aria-label="breadcrumb" style={{ display: "none" }}>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Account Settings</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Operation Addresses
                      </li>
                    </ol>
                  </nav>
                </div>
                {this.state.loading ? (
                  <CenterBlock height="150">
                    <Spinner size="2" />
                  </CenterBlock>
                ) : this.state.redirect ? (
                  <Redirect to={this.state.redirect} />
                ) : (
                  this.props.children
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
