import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../Logo";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import SpinnerButton from "../../Utils/SpinnerButton";
import Constants from "../../Utils/Constants";

export default class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loading: false,
    };
  }

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
  }

  upateProp(e, prop) {
    var record = this.state.newRecord;
    record[prop] = e.target.value;
    this.setState({ newRecord: record });
  }

  submit() {
    if (!this.state.email || this.state.email.trim() == "") {
      this.setState({ errorMessage: "Please supply your email address" });
      return;
    }

    this.setState({ errorMessage: null, loading: true });
    APICall("api/users/resetpassword", "POST", { Email: this.state.email })
      .then((data) => {
        if (data.data.status == "success") {
          this.setState({ redirectUrl: "/password-recovery-step1-success" });
        } else {
          this.setState({
            errorMessage: "Password recovery failed",
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo">
                  <Logo />
                </div>
                <h4 className="text-darker">Password recovery</h4>
                <h6 className="font-weight-light">
                  Provide your email below, we will help you regain access to
                  your account
                </h6>
                <form className="pt-3">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      placeholder="Email"
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </div>
                  {this.state.errorMessage ? (
                    <div className="mb-3">
                      <div className="alert alert-danger small" role="alert">
                        {this.state.errorMessage}
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-3">
                    <SpinnerButton
                      label="RECOVER PASSWORD"
                      loading={this.state.loading}
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={() => this.submit()}
                    />
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    {" "}
                    Return to
                    <a
                      href={Constants.subfolder + "/login"}
                      className="text-primary"
                    >
                      {" "}
                      Login
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
      </div>
    );
  }
}
