import React, { Component } from "react";
import { Link } from "react-router-dom";
import PublicWithTopStripBackground from "../../Components/Layouts/PublicWithTopStripBackground";
import Logo from "../../Logo";
import Constants from "../../Utils/Constants";

export default class SetPasswordSuccessful extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
  }

  render() {
    return (
      <PublicWithTopStripBackground
        children={
          <div className="col-12 col-md-6  mx-auto ">
            <div
              className="auth-form-light text-center p-5 shadow"
              style={{ borderRadius: "15px" }}
            >
              <div className="brand-logo">
                <Logo />
              </div>
              <h1>
                <i className="mdi mdi-lock text-success"></i>
              </h1>
              <h4 className="text-darker">Password setup is successful</h4>
              <h6 className="font-weight-light mb-5">
                You have successfuly set a password for your account, <br />
                you may now proceed to login.
              </h6>
              <a
                href={Constants.subfolder + "/login"}
                className="btn btn-success btn-lg btn-rounded font-weight-medium auth-form-btn"
              >
                LOGIN NOW
              </a>
            </div>
          </div>
        }
      />
    );
  }
}
