import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../Logo";
import Spinner from "../../Utils/Spinner";
import APICall from "../../Utils/APICall";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "../../sass/react-phone.scss";
import PublicWithTopStripBackground from "../../Components/Layouts/PublicWithTopStripBackground";
import VerifyCertificate from "../../Components/VerifyCertificate";

export default class VerifyCertificatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loading: false,
      redirectUrl: null,
      certificateNumber: null,
    };
  }

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
  }

  loadCertificate() {
    this.setState({ loading: true });
    APICall(`api/certificates/verify/${this.state.certificateNumber}`, "GET")
      .then((data) => {
        const response = data.data;
        if (response.isSuccess) {
          this.setState({
            redirectUrl: `/view-certificate/${this.state.certificateNumber}`,
          });
        } else {
          this.setState({ errorMessage: response.message, loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred, please check your network",
          loading: false,
        });
      });
  }

  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <PublicWithTopStripBackground
        children={
          <div className="col-lg-4 mx-auto">
            <div
              className="auth-form-light text-left p-5 shadow"
              style={{ borderRadius: "15px" }}
            >
              <div className="brand-logo">
                <Logo style={{width: "-webkit-fill-available" }} />
              </div>
              <h4 className="text-darker">Verify Certificate</h4>
              <VerifyCertificate returnUrl="/verify-certificate" />
            </div>
          </div>
        }
      />
    );
  }
}
