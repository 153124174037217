import React, { useState } from 'react'
import APICall from '../Utils/APICall';
import Constants from '../Utils/Constants';
import Spinner from '../Utils/Spinner';

function DownloadTag() {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [certificateNo, setCertificateNo] = useState('')

    const downloadCertificate = () => {
        console.log(certificateNo);
        setIsLoading(true);
        const certificateNumber = certificateNo?.trim();
        APICall(`api/certificates/verify/${certificateNumber}`, "GET")
            .then((data) => {
                const response = data.data;
                if(response.isSuccess){
                    window.open(`${Constants.apiBaseUrl}/api/certificates/downloadtag/${certificateNumber}`);
                    setIsLoading(false);
                    setErrorMessage('');
                    setCertificateNo('');
                }else {
                    setErrorMessage(response.message);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setErrorMessage("An error occurred, please check your network");
                setIsLoading(false);
            })
    }
  return (
    <div>
        <h6 className="font-weight-light">
          Enter certificate number below to continue.
        </h6>
        <form className="pt-2">
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              value={certificateNo}
              onChange={(e) =>
                setCertificateNo(e.target.value)
              }
            />
          </div>

          {errorMessage ? (
            <div className="mb-3">
              <div className="alert alert-danger small" role="alert">
                {errorMessage}
              </div>
            </div>
          ) : null}

          <div className="mt-3">
            {isLoading ? (
              <button
                type="button"
                className="btn btn-block btn-light btn-lg font-weight-medium auth-form-btn"
              >
                <Spinner size="1" />{" "}
              </button>
            ) : (
              <button
                id="confirm-phone-button"
                type="button"
                onClick={() => downloadCertificate()}
                className="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn"
              >
                Generate Tag
              </button>
            )}
          </div>
          <div className="text-center mt-4 font-weight-light"></div>
        </form>
    </div>
  )
}

export default DownloadTag